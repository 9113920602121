import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TitleBlock from "../components/title-block/title-block"

const TermsPage = () => (
    <Layout>
        <SEO title="Terms of use" />
        <TitleBlock title="Terms of use" />
        <div className="container">
            <p>Updated: 27/02/2021<br /><br />
                <strong>By viewing and using this site, you agree to these terms of use.</strong><br /><br />
                <h5>Agreement to terms</h5>
            Welcome to rpmaccounting.co.nz (the 'site'), a website hosted and operated by RPM Accounting Services Ltd.  ('we', 'our', 'RPM Accounting').<br /><br />

                <h5>RPM Accounting Services Ltd.</h5>

            These terms apply to the entire contents of the site, to any web feeds produced by the site and to any feedback or submissions provided through the site. By viewing and using the site, you will be deemed to agree to these terms of use.<br /><br />

                <h5>Disclaimer</h5>
            RPM Accounting Services Ltd. disclaims and excludes all liability for any claim, loss, demand or damages of any kind whatsoever (including for negligence) arising out of or in connection with the use of either this website or the information, content or materials included on this site or on any website we link to.<br /><br />

                <h5>Continuity of service</h5>
            RPM Accounting Services Ltd. makes no warranty or representation, express or implied, as to continuity of service. It reserves the right to suspend, terminate or otherwise alter access to some or all of the site's services at any time and without notice.<br /><br />

                <h5>Your licence to us for contributions to rpmaccounting.co.nz</h5>
            Other than personal information, which is covered under the privacy policy, any material you transmit or post to the site is considered non-confidential. This includes feedback or submissions you provide.<br /><br />

                <h5>Privacy</h5>
            We do not claim to own your copyright material. You do, however, grant RPM Accounting Services Ltd a non-exclusive, royalty-free and irrevocable licence to:<br /><br />
                <ul>
                    <li>publish such material in any media and in any format, including:
                    <ul>
                            <li>on this or any other website</li>
                            <li>in hard copy publications</li>
                            <li>in email notifications or alerts</li>
                            <li>in web feeds, and</li>
                        </ul>
                    </li>

                    <li>sub-license such material for re-use by others on the same terms as the licence you've granted RPM Accounting Services Ltd.</li>
                </ul>
            <h5>Third-party copyright</h5>
            If your contributions to the site include material in which copyright or other intellectual property is owned by a third party, you warrant that you have the right to use that material and to grant the licence referred to in, and on the terms of, the clause above.<br /><br />

                <h5>Revisions to these terms of use</h5>
            RPM Accounting Services Ltd may revise these terms of use at any time. Any such revisions take effect when this page is published.<br /><br />

            Contact us for more information.<br />

            Email: <a href="mailto:office@rpmaccounting.co.nz">office@rpmaccounting.co.nz</a></p>

        </div>
    </Layout>
)

export default TermsPage
